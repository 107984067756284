import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function ChatNotFound() {
  return (
    <div className="flex flex-col items-center p-5  rounded mt-4 text-center">
      <ChatBubbleBottomCenterTextIcon className="w-12 h-12s text-gray-300" />
      <div className=" text-gray-400 pt-2 text-lg"> Not Found</div>
      <span className="text-xs mt-1 text-gray-600">
        Oops! seems like there is not any chat here.
      </span>
    </div>
  );
}
