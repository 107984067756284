import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import oktaAuth from "./config/oktaConfig";
import SecureRoute from "./components/SecureRoute";
import Chat from "./components";

const App: React.FC = () => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    window.location.href = toRelativeUrl(
      originalUri || "/chat",
      window.location.origin
    );
  };

  const onAuthRequired = () => {
    oktaAuth.signInWithRedirect();
  };

  return (
    <Router>
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={onAuthRequired}
      >
        <Routes>
          <Route
            path="/login/callback"
            element={
              <LoginCallback
                loadingElement={<div>Loading authentication...</div>}
              />
            }
          />
          <Route
            path="/chat"
            element={
              <SecureRoute>
                <Chat />
              </SecureRoute>
            }
          />
          <Route path="/" element={<Navigate to="/chat" replace />} />
        </Routes>
      </Security>
    </Router>
  );
};

export default App;
