import {
  Bars3BottomLeftIcon,
  ChatBubbleLeftEllipsisIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useUserHistory } from "../hooks/useUserHistory";
import { formatTimeAgo } from "../utils/dateUtils";
import ResponseLoader from "./ResponseLoader";
import { useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
interface HistoryProps {
  onChatClick: (chat: any) => void;
  onDeleteChat: (chatId: string) => void;
  currentTopicId?: string | null;
}
export default function AllHistory(props: any) {
  const { history } = useUserHistory();
  const [checkId, setCheckId] = useState("");

  const toggleHistoryAccordion = (id: any) => {
    id == checkId ? setCheckId("") : setCheckId(id);
  };
  return (
    <div className="h-full p-8 overflow-auto">
      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold mb-3 max-md:pl-8">
          Chat History
        </h2>
        <Menu>
          {({ open }) => (
            <>
              <MenuButton className="text-gray-600 dark:text-gray-400 text-sm flex gap-1 items-center">
                All
                <ChevronDownIcon
                  className={`transition-transform h-4 w-4 ${
                    open ? "rotate-180" : "rotate-0"
                  }`}
                />
              </MenuButton>
              <MenuItems
                className="bg-white dark:border-primary-800 dark:bg-primary-900 rounded-lg shadow-lg border border-primary-200 py-1 min-w-[150px] flex flex-col w-80"
                anchor="bottom"
              >
                <MenuItem>
                  <button
                    className={`group px-4 py-2 text-left text-sm flex items-center gap-2 justify-between transition-opacity duration-300 data-[focus]:bg-blue-100 `}
                  >
                    General
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    className={`group px-4 py-2 text-left text-sm flex items-center gap-2 justify-between transition-opacity duration-300 data-[focus]:bg-blue-100 `}
                  >
                    Knowledge
                  </button>
                </MenuItem>
              </MenuItems>
            </>
          )}
        </Menu>
      </div>
      {props.data ? (
        props.data.map((conv: any) => {
          return (
            <>
              <div
                key={conv.id}
                className="group min-w-0 w-full flex gap-4 justify-between items-center relative group p-3 rounded-lg cursor-pointer bg-white mb-3 hover:shadow-lg hover:shadow-primary-alt-50 border border-gray-200 hover:border-primary-alt-100 "
              >
                <button
                  onClick={() => {
                    props.onChatClick(conv);
                    toggleHistoryAccordion("");
                  }}
                  className="min-w-0 w-full flex gap-4 items-center"
                >
                  <ChatBubbleLeftEllipsisIcon className="w-10 h-10 p-2 rounded-full group-hover:bg-white group-hover:text-primary-alt-600 bg-gray-100 text-gray-400" />
                  <div className="min-w-0 flex flex-col items-start w-full">
                    <div className="truncate text-gray-900 transition-all text-lg font-normal group-hover:text-primary-alt-600 w-full text-left">
                      {conv.title}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="rounded-full  text-xs text-gray-400 capitalize font-medium">
                        {conv.source}
                      </div>
                      <div className="w-1 h-1 rounded-full bg-gray-300"></div>
                      <div className="text-xs text-gray-500 self-start">
                        <span className="font-bold text-gray-950">
                          {conv.conversations.length}
                        </span>{" "}
                        Conversations
                      </div>
                      <div className="w-1 h-1 rounded-full bg-gray-300"></div>
                      <div className="text-xs text-gray-500 self-start">
                        {formatTimeAgo(new Date(conv.updated_at).getTime())}
                      </div>
                    </div>
                  </div>
                </button>
                <TrashIcon
                  onClick={(e) => {
                    props.showDeleteConfirm(conv.id);
                  }}
                  className="group-hover:opacity-100 group-hover:pointer-events-auto opacity-0 pointer-events-none w-4 h-4 hover:text-red-600 cursor-pointer"
                />
                {conv.conversations.length > 1 && (
                  <button
                    className="w-6 h-6 content-center"
                    onClick={() => toggleHistoryAccordion(conv.id)}
                  >
                    <ChevronDownIcon
                      className={`w-4 h-4 text-gray-400 transform transition-all duration-500  ${
                        checkId == conv.id ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                )}
              </div>
              <div
                className={`${
                  checkId == conv.id
                    ? "block  -mt-2 rounded cursor-pointer mb-4 ml-16 overflow-hidden "
                    : "hidden"
                }`}
              >
                {conv.conversations.length > 1 &&
                  conv.conversations.map((item: any, index: number) => {
                    return (
                      <button
                        key={item.id}
                        onClick={(e) => {
                          props.onChatClick({ ...conv }, item.id);
                        }}
                        className={`group/subchat rounded-lg flex gap-4 w-full items-center px-3 py-2 border-b border-gray-200 last:border-0 hover:bg-white hover:border-white  `}
                      >
                        <Bars3BottomLeftIcon
                          className={`w-4 h-4 min-w-4 text-gray-400`}
                        />
                        <div className="text-left min-w-0">
                          <div className="mb-1 truncate text-gray-900 transition-all text-lg font-normal group-hover/subchat:text-primary-alt-600 w-full text-left">
                            {item.prompt}
                          </div>
                          <div className="text-xs text-gray-400">
                            {formatTimeAgo(new Date(item.created_at).getTime())}
                          </div>
                        </div>
                      </button>
                    );
                  })}
              </div>
            </>
          );
        })
      ) : (
        <ResponseLoader />
      )}
    </div>
  );
}
