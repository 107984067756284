import React, { useRef, useState } from "react";
import { formatTimeAgo } from "../utils/dateUtils";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverBackdrop,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import {
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  DocumentIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";

interface ChatMessageProps {
  isAssistant?: boolean;
  content: string;
  userName?: string;
  timestamp?: number;
  id?: number;
  bot_citations: [];
}
type referenceType = {
  content: string;
  index: number;
  url: string;
};

type citationType = {
  references: referenceType[];
  text_fragment: string;
};

export const ChatMessage: React.FC<ChatMessageProps> = ({
  isAssistant = false,
  content,
  userName,
  timestamp,
  bot_citations,
  id,
}) => {
  const sources: string[] = [];
  let showSource = false;
  const UN = () => {
    if (userName && userName.trim() !== "") {
      const words = userName.split(" ").filter(Boolean); // Split sentence into words and remove extra spaces
      if (words.length > 1) {
        const firstLetterFirstWord = words[0][0]; // First letter of the first word
        const firstLetterLastWord = words[words.length - 1][0]; // First letter of the last word
        return firstLetterFirstWord + firstLetterLastWord; // Concatenate results
      } else if (words.length === 1) {
        return words[0][0]; // Handle case with only one word
      }
    }
    return ""; // Handle empty or undefined input
  };
  const userNAmeText = UN();
  const bottomRef = useRef(null);
  let overviewIndex = 1;
  return (
    <div
      className={`flex gap-3 rounded-tr-2xl rounded-bl-2xl text-gray-950 px-4 scroll-mt-8`}
      id={`${isAssistant ? `${id}_ai` : id}`}
    >
      {!isAssistant ? (
        <div className="min-w-6 min-h-6 w-6 h-6 md:min-w-10 md:min-h-10 md:h-10 text-xs md:text-base flex justify-center items-center text-primary-950 font-bold rounded-full bg-primary-200">
          {userNAmeText}
        </div>
      ) : (
        <div className="shrink-0 flex items-center justify-center min-w-6 min-h-6 w-6 h-6 md:min-w-10 md:min-h-10 md:h-10 rounded-full bg-white p-1 md:p-2">
          <img alt="logo" src="../../images/Favicon.svg" />
        </div>
      )}
      <div
        className={`flex-1 flex flex-col gap-0 rounded-tr-2xl rounded-bl-2xl text-gray-950  ${
          isAssistant
            ? "bg-white p-3 md:p-5  border border-primary-100 items-start hover:shadow-lg hover:shadow-primary-100 overflow-hidden"
            : "items-center "
        }`}
      >
        <div className="flex-1 w-full">
          <pre className="text-wrap font-sans">
            {content ?? content}
            {isAssistant && bot_citations.length == 0 && (
              <>{"Sorry, I am unable to assist you with this request."}</>
            )}
            {isAssistant &&
              bot_citations.length > 0 &&
              bot_citations.map(
                ({ references, text_fragment }: citationType) => {
                  return (
                    <>
                      {text_fragment
                        .replaceAll(new RegExp(".*?<userStyle>.*", "gm"), "")
                        .trim()}
                      {references.map((item, index) => {
                        showSource = true;
                        return (
                          <Popover
                            key={item.index}
                            className="relative inline-block ml-1"
                          >
                            <PopoverButton className="text-primary-alt-600">
                              [{item.index}]
                            </PopoverButton>
                            <PopoverBackdrop className="fixed inset-0 bg-black/15 z-40 " />
                            <PopoverPanel
                              anchor="bottom"
                              className=" z-50 bg-white dark:border-primary-800 dark:bg-primary-900 rounded-lg shadow-lg border border-primary-200 p-4 min-w-[180px] flex flex-col text-sm w-[450px] leading-normal "
                            >
                              <div className="flex gap-2 mb-1 items-center text-xl font-medium text-primary-alt-600">
                                <DocumentIcon className="h-5 w-5 min-w-5 " />
                                Overview{" "}
                                <ArrowTopRightOnSquareIcon className="h-4 w-4 min-w-4 text-primary-alt-600" />
                              </div>
                              <div className="flex gap-2 items-center  mb-3 pb-3 border-gray-100 border-b text-xs">
                                <PaperClipIcon className="h-3 w-3 min-w-3 mx-1  text-gray-600" />
                                <a
                                  className=" font-medium inline-block break-all hover:underline"
                                  href={item.url}
                                  target="_blank"
                                >
                                  {item.url}
                                </a>
                              </div>
                              <pre className="text-wrap font-sans line-clamp-6">
                                {" "}
                                {item.content}
                              </pre>
                            </PopoverPanel>
                          </Popover>
                        );
                      })}{" "}
                    </>
                  );
                }
              )}
            {isAssistant && showSource && (
              <div className={`mt-6 -mx-5 -mb-5 rounded-tr-2xl `}>
                <Disclosure>
                  <div className="px-5 mb-4">
                    <DisclosureButton className="group flex gap-2 items-center py-2 px-4 border border-gray-300 text-primary-alt-600 rounded-lg ">
                      Sources
                      <ChevronDownIcon className="h-3 w-3  transition duration-200 group-data-[open]:rotate-180" />
                    </DisclosureButton>
                  </div>
                  <DisclosurePanel
                    transition
                    className="text-gray-500 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0 rounded-tr-2xl "
                  >
                    <div className="bg-primary-50 p-5 pt-3 mt-4 rounded-tr-2xl ">
                      <div className="flex gap-2 mb-3 items-center text-lg font-medium text-primary-alt-600">
                        <DocumentIcon className="h-4 w-4 min-w-4" />
                        Overview{" "}
                        {/* <ArrowTopRightOnSquareIcon className="h-4 w-4 min-w-4 text-primary-alt-600" /> */}
                      </div>
                      {bot_citations.map(({ references }: citationType) => {
                        return (
                          <>
                            {references.map((item, index) => {
                              if (sources.includes(item.url)) return;
                              sources.push(item.url);
                              return (
                                <div
                                  className="flex gap-2 items-center mt-2"
                                  key={index}
                                >
                                  <span className="text-xs px-2 py-1 border inline-block rounded-lg bg-white">
                                    {overviewIndex++}
                                  </span>
                                  <PaperClipIcon className="h-4 w-4 mr-1 min-w-4 text-gray-600" />
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    className="text-sm block hover:underline text-gray-600"
                                  >
                                    {item.url}
                                  </a>
                                </div>
                              );
                            })}
                          </>
                        );
                      })}
                    </div>
                  </DisclosurePanel>
                </Disclosure>
              </div>
            )}
            <div></div>
          </pre>
        </div>
        {timestamp && !isAssistant && (
          <div className="text-xs text-gray-500 self-start capitalize">
            {formatTimeAgo(timestamp)}
          </div>
        )}
      </div>
    </div>
  );
};
