import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ArrowRightIcon,
  CameraIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  PaperClipIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { FileValidator } from "../utils/fileValidation";
import attachmentConfig from "../config/attachmentConfig";
import Spinner from "./Spinner";

interface PromptInputProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: (e: React.FormEvent, files?: File[]) => void;
  disabled: boolean;
  hasMessages: boolean;
  activeTab: string;
  setActiveTab: any;
  fileInputRef: React.RefObject<HTMLInputElement>;
  llm: Array<{ id: string; name: string }>;
  selectedModel: any;
  setSelectedModel: any;
  isSubmitting?: boolean;
  chatResponseType: any;
  selectedType: number;
  setSelectedType: React.Dispatch<React.SetStateAction<number>>;
  setSettingsValues: any;
  setCurrentTopicId: any;
  handleNewChat: any;
}

interface FileAttachment {
  file: File;
  id: string;
  validationStatus?: "pending" | "validating" | "valid" | "invalid";
  validationProgress?: number;
  validationError?: string;
}

const PromptInput: React.FC<PromptInputProps> = ({
  value,
  onChange,
  onSubmit,
  disabled,
  hasMessages,
  activeTab,
  setActiveTab,
  fileInputRef,
  llm,
  selectedModel,
  setSelectedModel,
  isSubmitting = false,
  chatResponseType,
  selectedType,
  setSelectedType,
  setSettingsValues,
  setCurrentTopicId,
  handleNewChat,
}) => {
  const [attachedFiles, setAttachedFiles] = useState<FileAttachment[]>([]);
  const [sessionTotalFileSize, setSessionTotalFileSize] = useState<number>(
    () => {
      // Initialize from localStorage, default to 0 if not set
      return parseInt(localStorage.getItem("sessionTotalFileSize") ?? "0", 10);
    }
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Utility function to update session total file size
  const updateSessionTotalFileSize = (newTotalFileSize: number) => {
    setSessionTotalFileSize(newTotalFileSize);
    localStorage.setItem("sessionTotalFileSize", newTotalFileSize.toString());
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    const errors: string[] = [];

    // Check total number of files
    const potentialTotalFiles = attachedFiles.length + files.length;
    if (potentialTotalFiles > attachmentConfig.MAX_ATTACHMENTS) {
      setErrorMessage(
        `Maximum of ${attachmentConfig.MAX_ATTACHMENTS} files allowed`
      );
      setTimeout(() => setErrorMessage(null), 3000);
      return;
    }

    for (let file of Array.from(files)) {
      try {
        // Validate file
        const validationResult = await FileValidator.validateFile(
          file,
          sessionTotalFileSize
        );

        // If file is invalid, add to errors and skip
        if (!validationResult.isValid) {
          errors.push(validationResult.error ?? `Invalid file: ${file.name}`);
          continue;
        }

        // Create file attachment for valid files
        const fileAttachment: FileAttachment = {
          file,
          id: `${file.name}-${file.size}-${Date.now()}`,
          validationStatus: "valid",
          validationProgress: 100,
        };

        // Update attached files with only valid files
        setAttachedFiles((current) => [...current, fileAttachment]);

        // Update total session file size
        const newTotalFileSize = sessionTotalFileSize + file.size;
        updateSessionTotalFileSize(newTotalFileSize);
        e.target.value = "";
      } catch (error) {
        errors.push(`Validation error for ${file.name}`);
      }
    }

    // Show errors if any
    if (errors.length > 0) {
      setErrorMessage(errors.join(", "));
      setTimeout(() => setErrorMessage(null), 5000);
    }
  };

  const removeFile = (fileToRemove: FileAttachment) => {
    setAttachedFiles((currentFiles) => {
      const newFiles = currentFiles.filter(
        (file) => file.id !== fileToRemove.id
      );

      // Recalculate total file size for valid files
      const newTotalFileSize = newFiles
        .filter((f) => f.validationStatus === "valid")
        .reduce((acc, f) => acc + f.file.size, 0);

      updateSessionTotalFileSize(newTotalFileSize);

      return newFiles;
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    // Pass files to parent's onSubmit method
    onSubmit(
      e,
      attachedFiles.length > 0 ? attachedFiles.map((f) => f.file) : undefined
    );

    // Clear attached files after submission
    setAttachedFiles([]);
    updateSessionTotalFileSize(0);
  };

  // Expose a method to clear session file size (for logout)
  const clearSessionFileSize = () => {
    updateSessionTotalFileSize(0);
  };

  // Tooltip for file upload

  const getStatusColor = (fileAttachment: FileAttachment) => {
    switch (fileAttachment.validationStatus) {
      case "pending":
        return "bg-gray-300";
      case "validating":
        return "bg-blue-500";
      case "valid":
        return "bg-green-500";
      case "invalid":
        return "bg-red-500";
      default:
        return "bg-gray-300";
    }
  };

  const settingValue_new = (
    token: any,
    temp: number,
    response_style_val: string
  ) => {
    setSettingsValues({
      max_token: token,
      temperature: temp,
      response_style: response_style_val,
    });
  };

  const renderFileAttachment = (fileAttachment: FileAttachment) => {
    return (
      <div
        key={fileAttachment.id}
        className={`flex items-center  text-gray-600 px-2 py-1 rounded-full text-sm relative overflow-hidden ${getStatusColor(
          fileAttachment
        )}`}
      >
        {/* Validation Progress Bar */}
        <div
          className={`absolute bottom-0 left-0 h-full bg-white`}
          style={{
            width: `${fileAttachment.validationProgress ?? 0}%`,
            transition: "width 0.3s ease-in-out",
          }}
        />

        <span className="max-w-[150px] truncate relative z-10 text-xs">
          {fileAttachment.file.name}
        </span>

        {fileAttachment.validationStatus === "invalid" && (
          <span
            className="text-red-500 ml-2 cursor-help relative z-10"
            title={fileAttachment.validationError}
          >
            ⚠️
          </span>
        )}

        <button
          type="button"
          onClick={() => removeFile(fileAttachment)}
          className="text-primary-700 hover:text-primary-900 ml-2 focus:outline-none relative z-10"
          aria-label="Remove file"
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>
    );
  };

  return (
    <div className="px-4">
      <div className="flex justify-center pl-4 pr-7">
        {!hasMessages && (
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => setActiveTab("knowledge")}
              className={`px-4 py-2 text-sm font-medium transition-colors relative  min-w-[100px] hover:bg-primary-200 dark:hover:bg-primary-800 dark:text-white  rounded-t-lg ${
                activeTab === "knowledge"
                  ? "bg-primary-200 dark:bg-primary-800"
                  : "text-gray-700 "
              }`}
            >
              Knowledge Base
            </button>
            <button
              type="button"
              onClick={() => setActiveTab("general")}
              className={`px-4 py-2 text-sm font-medium transition-colors relative  min-w-[100px] hover:bg-primary-200 dark:hover:bg-primary-800 dark:text-white  rounded-t-lg ${
                activeTab === "general"
                  ? "bg-primary-200 dark:bg-primary-800"
                  : "text-gray-700 "
              }`}
            >
              General
            </button>
          </div>
        )}
      </div>

      {/* Error Message Popup */}
      {errorMessage && (
        <div className="fixed top-4 right-4 z-50 bg-red-500 text-white p-3 rounded-lg shadow-lg">
          {errorMessage}
        </div>
      )}

      <form onSubmit={handleSubmit} className="relative">
        <div className={`relative w-full`}>
          <input
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
            placeholder={
              activeTab === "knowledge"
                ? "Search the knowledge base..."
                : "Send a message..."
            }
            className="z-0 w-full  pl-6 pr-36 py-4 border-2 text-gray-900 placeholder:text-gray-700 bg-white  border-primary-200  dark:text-gray-200 dark:placeholder:text-gray-400 dark:bg-primary-950 dark:border-primary-800 dark:focus:shadow-primary-900 rounded-full focus:outline-none focus:shadow-lg focus:shadow-primary-200"
          />
          <div className="flex items-center gap-2 absolute top-3 right-4">
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileUpload}
              multiple
              accept=".pdf,.docx,.pptx,.xlsx,.png,.jpeg,.jpg,.txt"
            />
            {activeTab !== "knowledge" && (
              <>
                <span
                  onClick={() => fileInputRef.current?.click()}
                  className="group relative text-gray-700 hover:text-primary-alt-800 hover:bg-primary-alt-200 dark:text-gray-400 p-2 rounded-full transition-colors duration-300 cursor-pointer"
                >
                  <PaperClipIcon className="h-5 w-5" />
                  <span
                    className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none opacity-0 w-48 text-left transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto left-1/2 -translate-x-1/2 bottom-10`}
                  >
                    <b>Upload Files</b>
                    <span className="text-gray-400 text-xs leading-tight">
                      <br />
                      Max 5MB per file
                      <br />
                      25MB total per session
                      <br />
                      Supported types: PDF, DOCX, PPTX, XLSX, PNG, JPEG, JPG,
                      TXT
                    </span>
                  </span>
                </span>
                <button
                  onClick={async () => {
                    try {
                      const stream =
                        await navigator.mediaDevices.getDisplayMedia({
                          video: true,
                          audio: false,
                        });

                      const track = stream.getVideoTracks()[0];
                      const video = document.createElement("video");
                      video.srcObject = stream;
                      await video.play();

                      const canvas = document.createElement("canvas");
                      canvas.width = video.videoWidth;
                      canvas.height = video.videoHeight;

                      const ctx = canvas.getContext("2d");
                      ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);

                      track.stop();

                      canvas.toBlob(
                        (blob) => {
                          if (blob) {
                            const screenshotFile = new File(
                              [blob],
                              `screenshot-${new Date()
                                .toISOString()
                                .replace(/[:.]/g, "-")}.png`,
                              { type: "image/png" }
                            );

                            const fileList = {
                              0: screenshotFile,
                              length: 1,
                              item: (index: number) =>
                                index === 0 ? screenshotFile : null,
                            } as unknown as FileList;

                            const syntheticEvent = {
                              target: { files: fileList },
                            } as React.ChangeEvent<HTMLInputElement>;

                            handleFileUpload(syntheticEvent);
                          }
                        },
                        "image/png",
                        1.0
                      );
                    } catch (error: unknown) {
                      if (
                        error instanceof Error &&
                        error.name === "NotAllowedError"
                      ) {
                        console.log("User cancelled screen selection");
                      } else {
                        console.error("Error taking screenshot:", error);
                        setErrorMessage("Failed to capture screenshot");
                        setTimeout(() => setErrorMessage(null), 3000);
                      }
                    }
                  }}
                  className="group relative text-gray-700 dark:text-gray-400 hover:text-primary-alt-800 hover:bg-primary-alt-200 p-2 rounded-full transition-colors duration-300 cursor-pointer"
                  title="Capture screenshot"
                >
                  <CameraIcon className="h-5 w-5" />
                  <span
                    className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute 2  mx-auto left-1/2 -translate-x-1/2 -top-8 whitespace-nowrap`}
                  >
                    Capture ScreenShot
                  </span>
                </button>
              </>
            )}
            <button
              type="submit"
              disabled={disabled || value.trim() === "" || isSubmitting}
              className={`group relative p-2 rounded-full transition-all duration-300  ${
                disabled || value.trim() === "" || isSubmitting
                  ? "text-gray-400 bg-gray-200 p-2 rounded-full transition-colors duration-300 cursor-not-allowed dark:text-gray-400 dark:bg-primary-900"
                  : "text-white bg-primary-alt-600 hover:bg-primary-alt-700 cursor-pointer"
              }`}
            >
              {isSubmitting ? (
                <Spinner />
              ) : (
                <ArrowRightIcon className="h-5 w-5" />
              )}
              <span
                className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute 2  mx-auto left-1/2 -translate-x-1/2 -top-8 whitespace-nowrap`}
              >
                Submit
              </span>
            </button>
          </div>
        </div>

        {/* File Attachments */}
        {attachedFiles.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-2">
            {attachedFiles.map(renderFileAttachment)}
          </div>
        )}
      </form>
      <div
        className={`flex  align-middle my-2 gap-4 px-4 ${
          hasMessages ? "justify-between" : "justify-center"
        } `}
      >
        <div className="flex gap-4">
          <Menu>
            {({ open }) => (
              <>
                <MenuButton className="text-gray-600 dark:text-gray-400 text-sm flex gap-1 items-center">
                  {llm.find((m: any) => m.id === selectedModel.id)?.name}
                  <ChevronDownIcon
                    className={`transition-transform h-4 w-4 ${
                      open ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </MenuButton>
                <MenuItems
                  className="bg-white rounded-lg shadow-lg border border-primary-200 py-1 min-w-[150px] flex flex-col dark:border-primary-800 dark:bg-primary-900"
                  anchor="bottom"
                >
                  {llm.map((model: any) => (
                    <MenuItem key={model.id}>
                      <button
                        className="w-full px-4 py-2 text-left text-sm hover:bg-[#FDF7F2] text-gray-700 hover:text-gray-900 data-[focus]:bg-blue-100 dark:text-gray-200"
                        onClick={() => {
                          setSelectedModel(model);
                        }}
                      >
                        {model.name}
                      </button>
                    </MenuItem>
                  ))}
                </MenuItems>
              </>
            )}
          </Menu>
          {activeTab !== "knowledge" && (
            <Menu>
              {({ open }) => (
                <>
                  <MenuButton className="text-gray-600 dark:text-gray-400 text-sm flex gap-1 items-center">
                    <PencilIcon className="h-4 w-4 text-primary-alt-400" />
                    {
                      chatResponseType.find((m: any) => m.type === selectedType)
                        ?.type
                    }
                    <ChevronDownIcon
                      className={`transition-transform h-4 w-4 ${
                        open ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </MenuButton>
                  <MenuItems
                    className="bg-white dark:border-primary-800 dark:bg-primary-900 rounded-lg shadow-lg border border-primary-200 py-1 min-w-[150px] flex flex-col w-80"
                    anchor="bottom"
                  >
                    {chatResponseType.map((model: any) => (
                      <MenuItem key={model.type}>
                        <button
                          className={`group px-4 py-2 text-left text-sm flex items-center gap-2 justify-between transition-opacity duration-300 data-[focus]:bg-blue-100 ${
                            selectedType === model.type
                              ? "bg-blue-50 dark:bg-primary-700"
                              : ""
                          } `}
                          onClick={() => {
                            setSelectedType(model.type);
                            settingValue_new(
                              model.tokens,
                              model.temperature,
                              model.type
                            );
                          }}
                        >
                          <span className="block">
                            <h4 className="font-bold mb-0 leading-tight dark:text-gray-200">
                              {model.type}
                            </h4>{" "}
                            <span className="text-xs text-gray-500 dark:text-gray-400">
                              {model.description}
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={` h-5 w-5 text-primary-alt-600 ${
                              selectedType === model.type
                                ? "opacity-100 "
                                : "opacity-0"
                            }`}
                          />
                        </button>
                      </MenuItem>
                    ))}
                  </MenuItems>
                </>
              )}
            </Menu>
          )}
        </div>
        {hasMessages && (
          <button
            onClick={() => {
              setCurrentTopicId("");
              handleNewChat();
            }}
            className="flex gap-1 items-center text-xs px-2 py-[2px] border border-gray-500 rounded-full text-gray-500 hover:text-white hover:bg-primary-alt-600 hover:border-primary-alt-600 transition duration-500"
          >
            Clear memories
          </button>
        )}
      </div>
    </div>
  );
};

export default PromptInput;
