const API_BASE =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api/v1";

const appConfig = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  apiBaseUrl: API_BASE,
  urls: {
    health: `${API_BASE}${process.env.REACT_APP_HEALTH_URL}`,
    model: `${API_BASE}${process.env.REACT_APP_MODELS_URL}`,
    modelList: () => `${process.env.REACT_APP_MODELS_URL}/list`,
    responseType: () => `${process.env.REACT_APP_MODELS_URL}/response/styles`,
    chat: `${API_BASE}${process.env.REACT_APP_CHAT_URL}`,
    chatHistory: (userId: string) =>
      `${API_BASE}${process.env.REACT_APP_CHAT_HISTORY_URL}`.replace(
        ":userId",
        userId
      ),
    deleteTopic: (userId: string, topicId: string) =>
      `${API_BASE}${process.env.REACT_APP_DELETE_TOPIC_URL}`
        .replace(":userId", userId)
        .replace(":topicId", topicId),
    deleteUserHistory: (userId: string) =>
      `${API_BASE}${process.env.REACT_APP_DELETE_USER_HISTORY_URL}`.replace(
        ":userId",
        userId
      ),
  },
  maxInputToken: process.env.REACT_APP_MAX_INPUT_TOKENS ?? 0,
  maxOutputToken: process.env.REACT_APP_MAX_OUTPUT_TOKENS ?? 0,
  temperature: process.env.REACT_APP_TEMPERATURE ?? 0,
  maxTokens: (): number => {
    const inputToken = parseInt(process.env.REACT_APP_MAX_INPUT_TOKENS ?? "0");
    const outputToken = parseInt(
      process.env.REACT_APP_MAX_OUTPUT_TOKENS ?? "0"
    );

    return inputToken + outputToken;
  },
};

export default appConfig;
