import attachmentConfig from "../config/attachmentConfig";

export interface FileValidationResult {
  isValid: boolean;
  error?: string;
}

export class FileValidator {
  // Helper method to get file extension
  private static getFileExtension(filename: string): string {
    const match = filename.match(/\.([^.]+)$/);
    const extension = match ? match[1].toLowerCase() : "";
    console.log("File extension extraction:", {
      filename,
      match,
      extension,
      fullExtensionCheck: `.${extension}`,
      allowedExtensions: attachmentConfig.ALLOWED_FILE_EXTENSIONS,
    });
    return extension;
  }

  // Validate file extension
  static validateFileExtension(filename: string): boolean {
    const extension = this.getFileExtension(filename);
    const isValid = attachmentConfig.ALLOWED_FILE_EXTENSIONS.includes(
      `.${extension}`
    );
    console.log("File extension validation:", {
      filename,
      extension,
      isValid,
    });
    return isValid;
  }

  // Validate MIME type
  static validateMimeType(mimeType: string): boolean {
    const isValid = attachmentConfig.ALLOWED_MIME_TYPES.includes(mimeType);
    console.log("MIME type validation:", {
      mimeType,
      isValid,
    });
    return isValid;
  }

  // File signature validation
  static async validateFileSignature(file: File): Promise<boolean> {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const arrayBuffer = event.target?.result as ArrayBuffer;
          const uint8Array = new Uint8Array(arrayBuffer);

          // Signature validation logic
          switch (file.type) {
            case "application/pdf":
              resolve(this.validatePdfSignature(uint8Array));
              break;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              resolve(this.validateDocxSignature(uint8Array));
              break;
            case "image/png":
              resolve(this.validatePngSignature(uint8Array));
              break;
            case "image/jpeg":
              resolve(this.validateJpegSignature(uint8Array));
              break;
            default:
              resolve(true);
          }
        } catch (error) {
          resolve(false);
        }
      };

      reader.onerror = () => resolve(false);
      reader.readAsArrayBuffer(file.slice(0, 1024)); // Read first 1KB
    });
  }

  // Virus scanning (placeholder - to be implemented later)
  static async virusScan(file: File): Promise<boolean> {
    // Simulate a slight delay to represent scanning
    return new Promise((resolve) => {
      setTimeout(() => {
        console.warn(
          "Virus scanning not implemented. Passing file by default."
        );
        resolve(true);
      }, 500);
    });
  }

  // Comprehensive file validation
  static async validateFile(
    file: File,
    currentSessionFileSize: number
  ): Promise<FileValidationResult> {
    // Filename validation
    if (!file.name || file.name.length > 255) {
      return { isValid: false, error: `Invalid filename: ${file.name}` };
    }

    // Extension validation
    if (!this.validateFileExtension(file.name)) {
      return { isValid: false, error: `Unsupported file type: ${file.name}` };
    }

    // MIME type validation
    if (!this.validateMimeType(file.type)) {
      return { isValid: false, error: `Unauthorized file type: ${file.name}` };
    }

    // File size validation
    if (file.size > attachmentConfig.MAX_SINGLE_FILE_SIZE) {
      return {
        isValid: false,
        error: `File too large: ${file.name} (max 5MB)`,
      };
    }

    // Total session file size validation
    if (
      currentSessionFileSize + file.size >
      attachmentConfig.MAX_TOTAL_FILE_SIZE
    ) {
      return {
        isValid: false,
        error: `Total file size cannot exceed 25MB in a session`,
      };
    }

    // Signature validation
    const isValidSignature = await this.validateFileSignature(file);
    if (!isValidSignature) {
      return {
        isValid: false,
        error: `Potentially corrupt file: ${file.name}`,
      };
    }

    // Virus scanning
    const isVirusFree = await this.virusScan(file);
    if (!isVirusFree) {
      return {
        isValid: false,
        error: `Potential security threat detected in: ${file.name}`,
      };
    }

    // All checks passed
    return { isValid: true };
  }

  // Specific signature validation methods
  private static validatePdfSignature(bytes: Uint8Array): boolean {
    return (
      bytes.length > 4 &&
      bytes[0] === 0x25 &&
      bytes[1] === 0x50 &&
      bytes[2] === 0x44 &&
      bytes[3] === 0x46
    );
  }

  private static validateDocxSignature(bytes: Uint8Array): boolean {
    return (
      bytes.length > 4 &&
      bytes[0] === 0x50 &&
      bytes[1] === 0x4b &&
      bytes[2] === 0x03 &&
      bytes[3] === 0x04
    );
  }

  private static validatePngSignature(bytes: Uint8Array): boolean {
    return (
      bytes.length > 8 &&
      bytes[0] === 0x89 &&
      bytes[1] === 0x50 &&
      bytes[2] === 0x4e &&
      bytes[3] === 0x47
    );
  }

  private static validateJpegSignature(bytes: Uint8Array): boolean {
    return (
      bytes.length > 4 &&
      ((bytes[0] === 0xff && bytes[1] === 0xd8) ||
        (bytes[0] === 0xff && bytes[1] === 0xd9))
    );
  }
}
