/*
    Currently, the max settings are per session
    These are stored in local storage
*/

const attachmentConfig = {
  MAX_ATTACHMENTS: 5,
  MAX_SINGLE_FILE_SIZE: 1024 * 1024 * 5, // 5MB
  MAX_TOTAL_FILE_SIZE: 1024 * 1024 * 25, // 25MB
  ALLOWED_FILE_EXTENSIONS: [
    ".pdf",
    ".xls",
    ".doc",
    ".docx",
    ".pptx",
    ".xlsx",
    ".png",
    ".jpg",
    ".jpeg",
    ".txt",
  ],
  ALLOWED_MIME_TYPES: [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "image/png",
    "image/jpeg",
    "text/plain",
  ],
};

export default attachmentConfig;
