import React, { useState, useRef, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ChatMessage } from "./ChatMessage";
import { useUserHistory } from "../hooks/useUserHistory";
import Sidebar from "./Sidebar";
import LogoNewChat from "./LogoNewChat";
import Greeting from "./Greeting";
import appConfig from "../config/appConfig";
import PromptInput from "./PromptInput";
import ResponseLoader from "./ResponseLoader";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import AllHistory from "./AllHistory";
import Spinner from "./Spinner";
import { messageType } from "../utils/messageUtils";

interface Message {
  role: "user" | "assistant";
  content: string;
  bot_citations: [];
  timestamp: number;
  id: number;
}

interface Citation {
  generatedResponsePart: {
    textResponsePart: {
      text: string;
      span: {
        start: number;
        end: number;
      };
    };
  };
}

interface Conversation {
  citations: Citation[];
  created_at: string;
  prompt: string;
  bot_response: string;
  id: number;
  model_id: string;
  model_name: string;
  model_version: string;
}

interface Topic {
  id: string;
  title: string;
  conversations: Conversation[];
  created_at: string;
  updated_at: string;
  source: string;
}

interface ApiResponse {
  success: boolean;
  message: string;
  error: string | null;
  status_code: number;
  data: {
    id: string;
  } | null;
}

type ModalType = {
  id: string;
  name: string;
  version: string;
};

export const Chat: React.FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const userId = authState?.idToken?.claims.sub;
  const { history, refreshHistory } = useUserHistory();

  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [currentTopicId, setCurrentTopicId] = useState<string>("");
  const [llmList, setllmList] = useState<any>([]);
  const [selectedModel, setSelectedModel] = useState<ModalType>({
    id: "",
    name: "",
    version: "",
  });
  const [selectedType, setSelectedType] = useState<number>(1);
  const [questionAskedState, setQuestionAskedState] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [checkId, setCheckId] = useState("");
  const [activeTab, setActiveTab] = useState("knowledge");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState({
    status: false,
    action: "",
  });
  const [showHistory, setShowHistory] = useState(false);

  const [settingsValues, setSettingsValues] = useState({
    max_token: 4096,
    temperature: 0.7,
    response_style: "Normal",
  });
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [chatResponseType, setChatResponseType] = useState([]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const getModelList = async () => {
    try {
      const response = await fetch(appConfig.urls.modelList());

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success) {
          const modelList: {
            id: string;
            name: string;
            version: string;
          }[] = responseData.data;
          setllmList(modelList);
          setSelectedModel(modelList[0]);
        } else {
          return [];
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getResponseStyle = async () => {
    try {
      const response = await fetch(appConfig.urls.responseType());

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success) {
          setChatResponseType(responseData.data);
          setSelectedType(responseData.data[0].type);
        } else {
          return [];
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getModelList();
    getResponseStyle();
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);
  const handleNewChat = () => {
    setShowHistory(false);
    setCurrentTopicId("");
    setMessages([]);
    setInputValue("");
    setActiveTab("knowledge");
    setSelectedModel(llmList[0]);
    localStorage.setItem("activeTab", "knowledge");
    if (window.innerWidth < 767) {
      setIsPinned(false);
    }
  };

  const handleChatClick = (chat: Topic, id = "") => {
    setShowHistory(false);
    setCurrentTopicId(chat.id);
    setActiveTab(chat.source);
    setSelectedModel({
      id: chat.conversations[chat.conversations.length - 1]?.model_id,
      name: chat.conversations[chat.conversations.length - 1]?.model_id,
      version: chat.conversations[chat.conversations.length - 1]?.model_version,
    });
    if (window.innerWidth < 767) {
      setIsPinned(false);
    }

    // Load existing conversations for this topic
    if (chat.conversations && chat.conversations.length > 0) {
      // Convert topic conversations to chat messages
      const existingMessages: Message[] = chat.conversations.flatMap((conv) => {
        const { bot_citations = [] } = conv as Partial<messageType>;
        return [
          {
            role: "user",
            content: conv.prompt,
            bot_citations: bot_citations,
            timestamp: conv.created_at
              ? new Date(conv.created_at).getTime()
              : Date.now(),
            id: conv.id,
          },
          {
            role: "assistant",
            content: "",
            bot_citations: bot_citations,
            timestamp: conv.created_at
              ? new Date(conv.created_at).getTime() + 1
              : Date.now() + 1,
            id: conv.id,
          },
        ];
      });
      // Set the messages for this topic
      setMessages(existingMessages);
    } else {
      // If no conversations, reset messages
      setMessages([]);
    }

    // Reset input value
    setInputValue("");
    const redirectToId = (id: string) => {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
          console.error(`Element with id "${id}" not found.`);
        }
      }, 100); // Add delay for rendering
    };
    if (id != "") {
      redirectToId(id);
    }
  };

  const handleSubmit = async (e: React.FormEvent, files?: File[]) => {
    e.preventDefault();

    if (!inputValue.trim() || !userId || isSubmitting) return;

    const newMessage: Message = {
      role: "user",
      content: inputValue.trim(),
      bot_citations: [],
      timestamp: Date.now(), // Use Date.now() to ensure valid timestamp
      id: 0,
    };

    setMessages((prev) => [...prev, newMessage]);
    setInputValue("");
    setIsSubmitting(true);

    try {
      const accessToken = authState?.accessToken?.accessToken;
      if (!accessToken) {
        throw new Error("No access token available");
      }

      // Prepare files for upload
      const filePromises = files
        ? files.map(async (file) => ({
            name: file.name,
            type: file.type,
            size: file.size,
            content: await fileToBase64(file),
          }))
        : [];

      const encodedFiles = await Promise.all(filePromises);
      const payload = {
        user_id: userId,
        topic_id: currentTopicId,
        prompt: newMessage.content,
        files: encodedFiles,
        response_type: {
          type: settingsValues.response_style,
          temperature: settingsValues.temperature,
          max_token: settingsValues.max_token,
          max_input_tokens: appConfig.maxInputToken,
          max_output_tokens: appConfig.maxOutputToken,
        },
        model: {
          id: selectedModel.id,
          name: selectedModel.name,
          version: selectedModel.version,
        },
        source: activeTab,
      };
      const response = await fetch(appConfig.urls.chat, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData: ApiResponse = await response.json();

        if (!responseData.success) {
          throw new Error(responseData.error || "API request failed");
        }
        if (!responseData?.data?.id) {
          throw new Error("No topic ID in response");
        }

        // Set current topic ID
        setCurrentTopicId(responseData.data.id);

        // Refresh history and get latest data
        const updatedHistory = await refreshHistory();

        // Get the latest conversation from the fresh history data
        const matchingTopic = updatedHistory
          ? updatedHistory.find((topic) => topic.id === responseData?.data?.id)
          : undefined;

        const latestConversation =
          matchingTopic?.conversations?.[
            matchingTopic.conversations.length - 1
          ];
        if (!latestConversation) {
          throw new Error("No conversation found in topic");
        }

        // Get the response text directly from latestConversation
        const { bot_citations = [] } =
          latestConversation as Partial<messageType>;
        if (!bot_citations) {
          throw new Error("No response text found in conversation");
        }

        const assistantMessage: Message = {
          role: "assistant",
          content: "",
          bot_citations: bot_citations,
          timestamp: Date.now(), // Use Date.now() to ensure valid timestamp
          id: 0,
        };

        setMessages((prev) => [...prev, assistantMessage]);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Utility function to convert File to Base64
  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDeleteTopic = async () => {
    setIsLoading({ status: true, action: "delete" });
    if (!userId) {
      throw new Error("User ID not found");
    }

    try {
      const accessToken = authState?.accessToken?.accessToken;
      if (!accessToken) {
        throw new Error("No access token available");
      }

      const response = await fetch(
        appConfig.urls.deleteTopic(userId, currentTopicId),
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete topic");
      }

      const responseData: ApiResponse = await response.json();

      if (!responseData.success) {
        throw new Error(responseData.error || "Delete topic failed");
      }
      setIsConfirm(false);
      // Clear current topic if deleted topic was the current one
      setCurrentTopicId("");
      setMessages([]);

      // Refresh history after successful deletion
      await refreshHistory();
    } catch (error) {
      console.error("Error deleting topic:", error);
      // Optional: Add user-facing error notification
    } finally {
      setIsLoading({ status: false, action: "" });
    }
  };

  const handleLogout = async () => {
    await oktaAuth.signOut();
  };

  if (!authState?.isAuthenticated) {
    return null;
  }

  const showDeleteConfirm = (chatId: any) => {
    setIsConfirm(true);
    setCurrentTopicId(chatId);
  };

  const toggleAccordion = (id: any) => {
    id == checkId ? setCheckId("") : setCheckId(id);
  };

  return (
    <div className="flex h-screen bg-gradient-to-tl from-primary-300 to-white md:py-3 md:pr-3 dark:bg-primary-700 dark:from-primary-950 dark:to-primary-900">
      <Sidebar
        isPinned={isPinned}
        setIsPinned={setIsPinned}
        showDeleteConfirm={showDeleteConfirm}
        data={history?.slice(0, 10) || []}
        handleLogout={handleLogout}
        onChatClick={handleChatClick}
        currentTopicId={currentTopicId}
        setShowHistory={setShowHistory}
        handleNewChat={handleNewChat}
        toggleAccordion={toggleAccordion}
        checkId={checkId}
      />

      <div
        className={`border border-primary-100 relative flex-1 flex flex-col bg-white bg-opacity-50 rounded-lg mx-auto w-full max-md:pt-20 dark:bg-primary-900 dark:border-primary-900 ${
          messages.length > 0 ? "" : "h-full overflow-y-auto justify-center"
        }`}
      >
        {!showHistory ? (
          <>
            <div
              className={`w-full mx-auto custom-scrollbar transition-all duration-500  ${
                messages.length > 0
                  ? "flex-1 overflow-visible overflow-y-auto"
                  : "pb-8"
              } ${questionAskedState ? "md:pr-64" : ""}`}
            >
              <LogoNewChat
                hasMessages={messages.length > 0}
                handleNewChat={handleNewChat}
              />

              <div className="max-w-5xl mx-auto">
                <Greeting
                  hasMessages={messages.length > 0}
                  name={authState.idToken?.claims.name}
                />
                {messages.length > 0 && (
                  <div className="w-full space-y-5 mt-7 transition-all">
                    {messages.map((message, index) => (
                      <ChatMessage
                        key={index}
                        isAssistant={message.role === "assistant"}
                        content={message.content}
                        bot_citations={message.bot_citations}
                        userName={authState.idToken?.claims.name}
                        timestamp={message.timestamp}
                        id={message.id}
                      />
                    ))}
                    {isSubmitting && <ResponseLoader />}
                    <div ref={messagesEndRef} />
                  </div>
                )}
              </div>
            </div>

            <div
              className={`flex-shrink-0 transition-all duration-500 ${
                questionAskedState ? "md:pr-64" : ""
              }`}
            >
              {/* <ChatMessageTooltips detail="123" title="testing" /> */}
              <div className="max-w-3xl mx-auto w-full py-4 md:pt-4">
                <PromptInput
                  value={inputValue}
                  onChange={setInputValue}
                  onSubmit={handleSubmit}
                  disabled={isSubmitting}
                  hasMessages={messages?.length > 0}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  fileInputRef={fileInputRef}
                  llm={llmList}
                  selectedModel={selectedModel}
                  setSelectedModel={setSelectedModel}
                  isSubmitting={isSubmitting}
                  chatResponseType={chatResponseType}
                  setSelectedType={setSelectedType}
                  selectedType={selectedType}
                  setSettingsValues={setSettingsValues}
                  setCurrentTopicId={setCurrentTopicId}
                  handleNewChat={handleNewChat}
                />
              </div>
            </div>
          </>
        ) : (
          <AllHistory
            onChatClick={handleChatClick}
            onDeleteChat={handleDeleteTopic}
            currentTopicId={currentTopicId}
            showDeleteConfirm={showDeleteConfirm}
            data={history}
          />
        )}
      </div>

      <Dialog
        open={isConfirm}
        onClose={() => {
          !isLoading.status && !isLoading.action && setIsConfirm(false);
        }}
        className={`relative z-50`}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-black/50 data-[closed]:opacity-0 duration-100"
        />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="max-w-lg space-y-4 border bg-white p-12 rounded-lg">
            <DialogTitle className="font-bold text-2xl text-red-600">
              Remove Chat
            </DialogTitle>
            <Description>
              This will permanently delete the selected chat
            </Description>
            <p>
              Are you sure you want to remove your selected chat history? All of
              your chat will be permanently removed.
            </p>
            <div className="flex gap-4 justify-end !mt-8">
              <button
                disabled={isLoading.status && isLoading.action == "delete"}
                autoFocus
                onClick={() => setIsConfirm(false)}
              >
                Cancel
              </button>
              <button
                disabled={isLoading.status && isLoading.action == "delete"}
                className={`py-3 px-5 bg-primary-alt-600 rounded text-white flex items-center gap-3 disabled:bg-primary-alt-300`}
                onClick={() => handleDeleteTopic()}
              >
                Yes, I am sure
                {isLoading.status && isLoading.action == "delete" && (
                  <Spinner />
                )}
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
};

export default Chat;
