import { Tab, TabGroup, TabList, TabPanels } from "@headlessui/react";
import {
  ArrowRightStartOnRectangleIcon,
  Bars3BottomLeftIcon,
  Bars3Icon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  ListBulletIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { memo, useState } from "react";
import ChatNotFound from "./NoChatFound";

interface ChatListItemProps {
  chat: any;
  onChatClick: (chat: any, id?: string) => void;
  showDeleteConfirm: any;
  toggleAccordion: any;
  checkId: any;
  currentTopicId: string;
}

interface SidebarProps {
  isPinned: boolean;
  showDeleteConfirm: any;
  setIsPinned: (value: boolean) => void;
  data: any[];
  handleLogout: () => void;
  onChatClick: (chat: any) => void;
  setShowHistory: (value: boolean) => void;
  handleNewChat: any;
  toggleAccordion: any;
  checkId: any;
  currentTopicId: string;
}
const Sidebar = memo((props: SidebarProps) => {
  const catLength = (cate: string) => {
    return props.data.filter((chat: any) => chat.source == cate).length;
  };
  const [chatCategory, setChatCategory] = useState("knowledge");
  return (
    <div
      className={`z-30 flex flex-col transition-all duration-300 ease-in-out -translate-x-full md:-translate-x-0 w-full  bg-white md:bg-transparent max-md:fixed max-md:bottom-0 max-md:top-0 max-md:right-0 max-md:left-0 max-md:z-20 ${
        props.isPinned
          ? "md:w-80 max-md:translate-x-0"
          : "md:w-20 max-md:-translate-x-full"
      }`}
    >
      <div className="flex items-center flex-col p-4">
        <button
          onClick={() => {
            props.setIsPinned(!props.isPinned);
          }}
          className={`group relative transition-all duration-300 ease-in-out  md:relative md:z-0  max-md:fixed max-md:-right-12 max-md:top-6 ${
            props.isPinned
              ? "ml-auto max-md:left-4"
              : "mx-auto  md:-right-0 z-20"
          }`}
        >
          <span
            className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none text-nowrap opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto left-full translate-x-2`}
          >
            Show/Collapse
          </span>
          <Bars3Icon className="h-6 w-6 text-gray-600 hover:text-primary-alt-600 dark:text-gray-400 dark:hover:text-gray-200" />
        </button>
      </div>
      <div className="px-4 max-md:mt-12">
        <button
          onClick={props.handleNewChat}
          className="group relative hover:bg-primary-alt-600 hover:text-white group flex items-center gap-2 rounded-md px-3 py-2 mb-4 transition-colors duration-300 border border-primary-alt-600 text-primary-alt-600 dark:text-primary-400 dark:border-primary-700 dark:hover:bg-primary-700 w-full justify-center"
        >
          <PlusIcon className="w-4 h-4" />
          {props.isPinned ? (
            <span className="inline-block text-sm">New Chat</span>
          ) : (
            <span
              className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none text-nowrap opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto ${
                props.isPinned
                  ? "left-1/2 -translate-x-1/2 -top-8"
                  : "left-full translate-x-2 top-1/2 -translate-y-1/2"
              }`}
            >
              New Chat
            </span>
          )}
        </button>
      </div>
      <div
        className={`flex-1  transition-opacity duration-300 ease-in-out md:group-hover:opacity-100 relative`}
      >
        <div
          className={`px-4 flex-1 overflow-y-auto ${
            props.isPinned
              ? "h-[calc(100vh-230px)] md:opacity-100"
              : "md:opacity-0 hidden"
          }`}
        >
          {props.data.length === 0 ? (
            <ChatNotFound />
          ) : (
            <TabGroup className="mb-2">
              <div className=" -mx-4 px-4 py-1 mb-2 sticky top-0 z-10">
                <TabList className="flex gap-1 my-2 bg-primary-100 rounded-full p-1 ">
                  <Tab
                    onClick={() => setChatCategory("knowledge")}
                    className="font-bold text-gray-500 grow data-[selected]:bg-primary-900 data-[selected]:text-white data-[hover]:bg-white data-[selected]:hover:bg-primary-900 px-4 py-2 text-xs  rounded-full"
                  >
                    <span> Knowledge</span>
                    <span className="px-2 leading-none rounded-full text-gray-500 bg-white ml-2">
                      {catLength("knowledge")}
                    </span>
                  </Tab>
                  <Tab
                    onClick={() => setChatCategory("general")}
                    className="font-bold text-gray-500 grow data-[selected]:bg-primary-900 data-[selected]:text-white data-[hover]:bg-white data-[selected]:hover:bg-primary-900 px-4 py-2 text-xs  rounded-full ilnine-block"
                  >
                    <span>General</span>
                    <span className="px-2 leading-none rounded-full text-gray-500 bg-white ml-2">
                      {catLength("general")}
                    </span>
                  </Tab>
                </TabList>
              </div>
              <TabPanels>
                <ul className="space-y-1">
                  {catLength(chatCategory) > 0 ? (
                    props.data
                      .filter((chat: any) => chat.source == chatCategory)
                      .map((chat: any) => {
                        return (
                          <ChatListItem
                            key={chat.id}
                            chat={chat}
                            onChatClick={props.onChatClick}
                            showDeleteConfirm={props.showDeleteConfirm}
                            toggleAccordion={props.toggleAccordion}
                            checkId={props.checkId}
                            currentTopicId={props.currentTopicId}
                          />
                        );
                      })
                  ) : (
                    <ChatNotFound />
                  )}
                </ul>
              </TabPanels>
            </TabGroup>
          )}
        </div>
        {props.data.length != 0 && props.isPinned && (
          <div className="text-center border-t border-primary-200 py-3">
            <button
              onClick={() => {
                props.setShowHistory(true);
                if (window.innerWidth < 767) {
                  props.setIsPinned(false);
                }
              }}
              className="hover:text-primary-alt-600 cursor-pointer text-xs font-medium text-primary-900 dark:text-gray-400 dark:hover:text-primary-alt-400 "
            >
              <span>View All</span>
            </button>
          </div>
        )}
      </div>
      <div
        className={`flex justify-center items-center text-center  gap-1 ${
          props.isPinned ? "flex-row" : "flex-col"
        }`}
      >
        {props.data.length != 0 && !props.isPinned && (
          <button
            onClick={() => props.setShowHistory(true)}
            className="group relative text-gray-700 hover:text-primary-alt-800 hover:bg-primary-alt-200 p-2 rounded-full transition-colors duration-300 cursor-pointer dark:text-gray-400 "
          >
            <ChatBubbleLeftRightIcon className="w-6 h-6" />
            <span
              className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto text-nowrap ${
                props.isPinned
                  ? "left-1/2 -translate-x-1/2 -top-8"
                  : "left-full translate-x-2 top-1/2 -translate-y-1/2"
              }`}
            >
              View All
            </span>
          </button>
        )}
        <a
          href="https://beyondid.atlassian.net/wiki/spaces/BIDAIAW/pages/3288563713/BeyondID+AI+Work+General+Help+Page"
          target="_blank"
          className="group relative text-gray-700 hover:text-primary-alt-800 hover:bg-primary-alt-200 p-2 rounded-full transition-colors duration-300 cursor-pointer dark:text-gray-400 "
        >
          <QuestionMarkCircleIcon className="w-6 h-6" />
          <span
            className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none opacity-0 transition-opacity bg-gray-800 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto ${
              props.isPinned
                ? "left-1/2 -translate-x-1/2 -top-8"
                : "left-full translate-x-2 top-1/2 -translate-y-1/2"
            }`}
          >
            Help
          </span>
        </a>

        <button
          title="Logout"
          onClick={props.handleLogout}
          className="group relative text-gray-700 hover:text-primary-alt-800 hover:bg-primary-alt-200 p-2 rounded-full transition-colors duration-300 cursor-pointer dark:text-gray-400 "
        >
          <ArrowRightStartOnRectangleIcon className="w-6 h-6" />
          <span
            className={`group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none opacity-0 transition-opacity bg-red-600 px-2 py-1 text-sm text-gray-100 rounded-md absolute mx-auto ${
              props.isPinned
                ? "left-1/2 -translate-x-1/2 -top-8"
                : "left-full translate-x-2 top-1/2 -translate-y-1/2"
            }`}
          >
            Logout
          </span>
        </button>
      </div>
    </div>
  );
});

const ChatListItem = memo(
  ({
    chat,
    onChatClick,
    showDeleteConfirm,
    toggleAccordion,
    checkId,
    currentTopicId,
  }: ChatListItemProps) => {
    const messageCount = chat.conversations?.length || 0;
    const tooltipText = `${chat.title}\n(${messageCount} message${
      messageCount !== 1 ? "s" : ""
    })`;
    return (
      <li key={`${chat.id}-list`} id={chat.id}>
        <span className="group relative flex gap-2 items-start">
          <button
            onClick={() => {
              onChatClick({ ...chat });
              toggleAccordion("");
            }}
            title={tooltipText}
            className={`md:group flex-wrap flex-1 font-medium hover:bg-white flex items-center gap-2 rounded-md px-2 py-1.5 transition-colors duration-300 text-[#171717] dark:text-gray-400 dark:group-hover:text-gray-200 dark:group-hover:bg-primary-800 text-sm cursor-pointer ${
              checkId == chat.id || currentTopicId == chat.id
                ? "bg-white text-primary-alt-600"
                : ""
            }`}
          >
            <ChatBubbleLeftEllipsisIcon className="w-4 h-4 mt-1 shrink-0 group-hover:text-primary-alt-600 dark:group-hover:text-gray-50" />
            <div
              className={`min-w-0 max-w-56  line-clamp-2 flex-1 text-sm text-left `}
            >
              {chat.title}
            </div>
          </button>
          {chat.conversations.length > 1 && (
            <button
              className="mt-2 md:group-hover:opacity-100 md:group-hover:pointer-events-auto md:pointer-events-none md:opacity-0 text-gray-500 hover:text-red-600"
              onClick={() => toggleAccordion(chat.id)}
            >
              <ListBulletIcon className="h-4 w-4" title="Show all queries" />
            </button>
          )}
          <TrashIcon
            title="Remove Conversation"
            className="mt-2 md:group-hover:opacity-100 md:group-hover:pointer-events-auto md:pointer-events-none md:opacity-0 shrink-0 w-3 h-3 hover:text-red-600 dark:text-gray-400 dark:hover:text-red-300 cursor-pointer"
            onClick={() => showDeleteConfirm(chat.id)}
          />
        </span>
        {chat.conversations.length > 1 && (
          <span className="block pl-2">
            <ul
              className={`mt-1 text-sm transition-all duration-500 w-full ${
                checkId == chat.id
                  ? "max-h-96 overflow-auto opacity-100"
                  : "h-0 overflow-hidden opacity-0"
              }`}
            >
              {chat.conversations.map((item: any) => (
                <li key={item.id} className="flex gap-2 items-center my-1">
                  <Bars3BottomLeftIcon className="h-3 w-3 text-gray-400" />
                  <button
                    key={item.id}
                    className="cursor-pointer py-1 w-full hover:text-primary-alt-600 min-w-0 max-w-60  line-clamp-2 flex-1 text-sm text-left"
                    onClick={() => {
                      onChatClick({ ...chat }, item.id);
                    }}
                  >
                    {item.prompt}
                  </button>
                </li>
              ))}
            </ul>
          </span>
        )}
      </li>
    );
  }
);

export default Sidebar;
