import React from "react";

const ResponseLoader: React.FC = () => {
  return (
    <div className="text-center">
      <span className="loader mx-auto flex items-center justify-center">
        <img
          alt="loader"
          width="75px"
          height="75px"
          src="./images/loader.svg"
        />
      </span>
      <style>{`
        .loader {
  box-sizing: border-box;
  position: relative;
  width: 100px;
  height: 100px;
  animation: loader 1.5s linear infinite;
}

@keyframes loader {
0{transform: rotate(0deg);}
  70% {
    transform: rotate(360deg) scale(0.25);  opacity:0.5;
    100%{
    transform: scale(1);
    }
    }
}
    
      `}</style>
    </div>
  );
};

export default ResponseLoader;
